import React, { useEffect, useRef, useState } from 'react';
import { TransLink } from 'components/trans/transLink';

import './factsHero.scss';
import { factsDataType } from 'data/facts.data';
import { FactsShare } from '../share/factsShare';
import parse from 'html-react-parser';
import Flickity from 'react-flickity-component';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import { HTMLDOMElement } from 'highcharts';
import classNames from 'classnames';
import ModalNewsletter from 'components/modalNewsletter/modalNewsletter';
import gsap from 'gsap';
import FactsQuellen from '../quellen/factsQuellen';

interface Props {
  facts: factsDataType[];
}

export const FactsHero: React.FC<Props> = (props) => {
  const ref = useRef(null);
  const [layer, setLayer] = useState<number>(0);
  const layerRef = useRef<number>(0);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const flickityOptions = {
    draggable: true,
    wrapAround: false,
    prevNextButtons: false,
  };

  const maxFacts = props.facts.length;

  useEffect(() => {
    if (ref.current) {
      const flk = (ref.current as any).flkty;
      // when flickity is done setting up
      flk.on('ready', () => {
        const items: any[] = flk.cells as any[];
        flk.on('change', (index: number) => {
          setLayer(index);
          layerRef.current = index;
        });
        flk.on('scroll', (progress: number) => {
          // force slider to remain still. will transform each element instead of
          // moving the slider
          flk.slider.style.transform = 'translateX(0%)';

          const length = flk.slides.length;
          items.forEach((item, index) => {
            if (index >= layerRef.current - 1 && index <= layerRef.current + 1) {
              const dir = index % 2 === 0 ? 1 : -1;
              const progressThreshold = index * (1 / (length - 1));
              const percentage = Math.max(0, -100 * (progress - progressThreshold) * (length - 1));
              (item.element as HTMLDOMElement).style.transform = `translateX(calc(${percentage}% + 15% * ${percentage * 0.01})) rotate(calc(${
                percentage * dir
              } * 0.15deg))`;
            }
          });
        });
        // items.forEach((item, index) => {
        //   (item.element as HTMLDOMElement).style.transform = `translateX(0%) rotateZ(0%)`;
        // });
      });
    }
  }, []);
  useEffect(() => {
    if (ref.current) {
      const flk = (ref.current as any).flkty;
      const items: any[] = flk.cells;
      const length = items.length;
      const index = flk.selectedIndex;
      const progress = index * (1 / (length - 1));
      items.forEach((item, index) => {
        if (index >= layerRef.current - 1 && index <= layerRef.current + 1) {
          const dir = index % 2 === 0 ? 1 : -1;
          const progressThreshold = index * (1 / (length - 1));
          const percentage = Math.max(0, -100 * (progress - progressThreshold) * (length - 1));
          (item.element as HTMLDOMElement).style.transform = `translateX(calc(${percentage}% + 15% * ${percentage * 0.01})) rotate(calc(${
            percentage * dir
          } * 0.15deg))`;
        }
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    if (!modalShown && layer === 5) {
      setModalShown(true);
      setModalOpen(true);
      // newsletter / modal / popup
    }
  }, [layer, modalShown]);

  const handleScrollToAllFacts = () => {
    setModalOpen(false);
    const menu = document.getElementsByClassName('menu')[0];
    const navHeight = menu?.clientHeight || 0;
    gsap.to(window, {
      duration: 0.4,
      scrollTo: window.innerHeight - navHeight,
    });
  };

  return (
    <div className="facts-hero" id="hero">
      <Flickity options={flickityOptions} ref={ref}>
        {props.facts.map((fact, index) => (
          <div key={index} className={classNames('facts-hero__wrapper', { 'is-fixed': index < layer })}>
            <div className="facts-hero__inner">
              <div className="facts-text__wrapper">
                <h5 className="facts-hero__number section-header__title">
                  Fakten statt Meinungen <span>{fact.id}</span>/{maxFacts}
                </h5>
                <div className="facts-hero__text">{parse(fact.text)}</div>
                <div className="facts-hero__source">
                  <FactsQuellen {...fact} />
                </div>
              </div>
              <FactsShare {...fact} />
            </div>
          </div>
        ))}
      </Flickity>
      <div className={classNames('facts-flickity__arrows')}>
        <div
          className="facts-hero__arrowwrapper"
          onClick={() => {
            if (ref.current) {
              ((ref.current as any).flkty as Flickity).previous();
            }
          }}
        >
          <ArrowLeft className={classNames('facts-hero__arrow', 'is-left', { 'is-visible': layer !== 0 })} />
        </div>
        <div
          className="facts-hero__arrowwrapper"
          onClick={() => {
            if (ref.current) {
              ((ref.current as any).flkty as Flickity).next();
            }
          }}
        >
          <ArrowRight className={classNames('facts-hero__arrow', 'is-right', { 'is-visible': layer !== props.facts.length - 1 })} />
        </div>
      </div>
      <div className="facts-hero__all-facts">
        <div className="facts-hero__all-facts__button" onClick={handleScrollToAllFacts}>
          Alle Fakten
        </div>
      </div>
      <ModalNewsletter visible={modalOpen} onClose={() => setModalOpen(false)} onShowAllFacts={handleScrollToAllFacts} />
      {/* {!!modalOpen && <ModalNewsletter onClose={() => setModalOpen(false)} onShowAllFacts={handleScrollToAllFacts} />} */}
    </div>
  );
};
