import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './modalNewsletter.scss';
import ModalCloseSvg from '../../assets/modal-close.svg';
import { TransLink } from 'components/trans/transLink';
import classNames from 'classnames';

interface Props {
  onClose: () => void;
  onShowAllFacts: () => void;
  visible: boolean;
}

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

const ModalNewsletter: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.visible) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, [props.visible]);

  if (!portalRoot) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className={classNames('modal-newsletter', { 'is-visible': props.visible })}>
      <div className="modal-newsletter__inner">
        <div className="modal-newsletter__iconwrapper" onClick={props.onClose}>
          <div className="modal-newsletter__close">
            <ModalCloseSvg />
          </div>
        </div>

        <h5 className="section-header__title">Einfach Fakten. Direkt in Ihren Posteingang.</h5>
        <div className="modal-newsletter__text">
          <p>
            Knapp, kurz und klar: Sie erhalten alle zwei Monate fünf erhellende Fakten rund um die Wirtschaftspolitik in der Schweiz. Wenn gefühlte
            Wahrheiten eine Diskussion prägen, können Sie mit belastbaren Fakten glänzen. Der neue IWP-Newsletter dient als verlässliche
            Informationsquelle – es ist unser Beitrag für Ihre fundierte Meinungsbildung.
          </p>
        </div>
        <div className="modal-newsletter__buttons">
          <TransLink to="/newsletter-facts" className="modal-newsletter__button">
            Hier anmelden
          </TransLink>
          <div className="modal-newsletter__button" onClick={props.onShowAllFacts}>
            Alle Fakten
          </div>
        </div>
      </div>
    </div>,
    portalRoot
  );
};

export default ModalNewsletter;
