import { ImgProps } from 'components/img/img';
import React from 'react';
import Helmet from 'react-helmet';

export interface SEOProps {
  title: string;
  metaDesc: string;
  opengraphImage: ImgProps;
}

interface Props extends SEOProps {
  fact?: {
    id: string;
    text: string;
  };
}

const SEOFacts: React.FC<Props> = (props) => {
  const isBrowser = typeof window !== 'undefined';
  const url = isBrowser ? window.location : '';
  const origin = isBrowser ? window.location.origin : '';

  const text = !!props.fact
    ? props.fact.text
        .replaceAll(/(<([^>]+)>)/gi, '')
        .replaceAll('&thinsp;', ' ')
        .replaceAll('&shy;', '')
    : props.metaDesc;

  // console.log(props.fact);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'de-DE',
      }}
    >
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta name="description" content={text} />
      <meta property="og:description" content={text} />
      {!!props.opengraphImage && props.opengraphImage.localFile && props.opengraphImage.localFile.publicURL && !props.fact ? (
        <meta property="og:image" content={'https://www.iwp.swiss' + props.opengraphImage.localFile.publicURL} />
      ) : (
        <meta property="og:image" content={`https://www.iwp.swiss/facts-share/facts-share-${String(props.fact?.id).padStart(3, '0')}.jpg`} />
      )}
      <meta property="og:url" content={`${url}`} />
      <meta name="twitter:card" content="summary_large_image"></meta>
    </Helmet>
  );
};

export default SEOFacts;
