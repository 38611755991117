import { graphql } from 'gatsby';
import SEO from 'components/seo/seo';
import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import './facts.template.scss';

import { factsDataType } from 'data/facts.data';
import { shuffle } from 'lodash';
import parse from 'html-react-parser';
import FactsBlock from 'components/facts/factsBlock';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FactsHero } from 'components/facts/hero/factsHero';
import { Link, useLocation } from '@reach/router';
import SEOFacts from 'components/seo/seoFacts';
import ModalNewsletter from 'components/modalNewsletter/modalNewsletter';
import { TransLink, handlePageLoaded } from 'components/trans/transLink';

interface Props {
  pageContext: pageContextProps;
  data: {
    allWpFact: {
      nodes: {
        AcfFacts: {
          id: string;
          text: string;
          link?: string;
          sources: {
            link: string;
            text: string;
          }[];
        };
      }[];
    };
  };
}

const FactsTemplate: React.FC<Props> = (props) => {
  const { title, id, seo } = props.pageContext;

  const [data, setData] = useState<factsDataType[]>();
  const [heroData, setHeroData] = useState<factsDataType[]>();
  const [hashIndex, setHashIndex] = useState<number | undefined>(-1);
  const { hash, state } = useLocation();

  const maxFacts = props.data.allWpFact.nodes.length;

  // console.log('facts', props.data.allWpFact.nodes);

  useEffect(() => {
    // facts data sorted by id property in descending order
    const factsData = props.data.allWpFact.nodes.map((node) => node.AcfFacts).sort((a, b) => parseInt(b.id) - parseInt(a.id));

    setData(factsData);

    let _heroData = [...factsData];
    let hashIndex = undefined;
    let heroFirstIndex: factsDataType[] = [];
    if (!!hash) {
      hashIndex = parseInt(hash.replace('#', '')) - 1;
      // console.log('hashIndex', hashIndex);
      // console.log('heroData', _heroData);
      const highlightedSlide = _heroData.splice(maxFacts - 1 - hashIndex, 1);
      // console.log('hashIndex', hashIndex);
      // console.log('highlightedSlide', highlightedSlide);
      // console.log('heroData', _heroData);
      heroFirstIndex = highlightedSlide;
      _heroData = [highlightedSlide[0], ..._heroData];
    }
    setHashIndex(hashIndex);
    // with shuffling
    // setHeroData([...heroFirstIndex, ...shuffle(_heroData)]);

    // without shuffling
    setHeroData([..._heroData]);

  }, []);

  useEffect(() => {
    handlePageLoaded();
  }, []);


  // Scroll to the element with the ID from state.scrollTo
  useEffect(() => {
    if (state?.scrollTo) {
      setTimeout(() => {
        const el = document.getElementById(state.scrollTo);
        if (!!el) {
          const top = el.getBoundingClientRect().top + window.pageYOffset - 150;
          window.scrollTo({ top, behavior: 'smooth' });
        }
      } , 100);
    }
  }, []);

  useEffect(() => {
    if (!!data) {
      const blocks = document.querySelectorAll('.facts-block__inner');
      blocks.forEach((item) => {
        const direction = Math.random() > 0.5 ? 1 : -1;
        const delay = Math.random() * 0.5;

        gsap.to(item, {
          scrollTrigger: {
            trigger: item.parentElement,
            start: 'top bottom',
          },
          startAt: {
            y: '200%',
            rotateZ: `${-direction * 12}deg`,
            x: `${40 * direction}%`,
          },
          opacity: 1,
          y: '0%',
          x: '0%',
          rotateZ: '0deg',
          duration: 0.5 + delay / 2,
          delay: delay,
          ease: 'pow3.out',
        });
      });
    }
  }, [data]);

  let factsShareIndex = undefined;
  if (!!hash) {
    factsShareIndex = parseInt(hash.replace('#', '')) - 1;
  }

  return (
    <div className="facts-template" id={id}>
      <SEOFacts {...seo} fact={factsShareIndex != undefined ? data?.[factsShareIndex] : undefined} />
      {!!heroData && <FactsHero facts={heroData} />}
      <section id="facts-overview" className="section facts-overview">
        <div className="section-header">
          <h5 className="section-header__title">{title}</h5>
        </div>

        <div className="facts__wrapper">
          <p>
            Das Institut für Schweizer Wirtschaftspolitik (IWP) an der Universität Luzern möchte eine verlässliche, faktenbasierte Stimme in der
            wirtschaftspolitischen Diskussion sein. Mit regelmässig erscheinenden <TransLink to="/publikationen/">Publikationen</TransLink>,{' '}
            <TransLink to="/newsletter/">Newsletter</TransLink> und <TransLink to="/agenda/">Veranstaltungen</TransLink> nimmt das IWP eine
            Brückenfunktion zwischen Wissenschaft und Gesellschaft ein und trägt zur gesellschaftlichen Meinungsbildung bei. So geht
            Wirtschaftspolitik für alle.
          </p>
          <p>Alle Links abgerufen am: 2. Juni 2023.</p>
          <div className="facts__boxes">
            <div className="facts__boxes__row">{!!data && data.map((item, key) => <FactsBlock {...item} maxFacts={maxFacts} key={key} />)}</div>
          </div>
        </div>
        <div className="section-content page-content"></div>
      </section>
    </div>
  );
};

export default FactsTemplate;

export const query = graphql`
  query {
    allWpFact(sort: {order: DESC, fields: date}) {
      nodes {
        AcfFacts {
          id
          text
          link
          sources {
            link
            text
          }
        }
      }
    }
  }
`;
